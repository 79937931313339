"use client";

import React, { FC, useState, useEffect } from "react";
import { useSearchParams } from 'next/navigation';
import StaySearchForm from "./(product-search-form)/StaySearchForm";
import ExperiencesSearchForm from "./(experiences-search-form)/ExperiencesSearchForm";
import RentalCarSearchForm from "./(car-search-form)/RentalCarSearchForm";
import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";

export type SearchTab = "Activités aériennes" | "Événements" | "Vols privés" | "Transferts";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Activités aériennes" | "Événements" | "Vols privés" | "Transferts";
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Activités aériennes",
  currentPage,
}) => {
  const tabs: SearchTab[] = ["Activités aériennes", "Événements", "Vols privés", "Transferts"];
  const searchParams = useSearchParams();
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  useEffect(() => {
    const cat = searchParams.get('cat');
    if (cat && tabs.includes(cat as SearchTab)) {
      setTabActive(cat as SearchTab);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTab = () => {
    return (
      <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => setTabActive(tab)}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? ""
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              {active && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
              )}
              <span>{tab}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  // background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  //               radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);

  const renderForm = () => {
    switch (tabActive) {
      case "Activités aériennes":
        return <StaySearchForm />;
      case "Événements":
        return <RentalCarSearchForm />;
      // case "Vols privés":
      //   return <FlightSearchForm />;
      // case "Transferts":
      //   return <ExperiencesSearchForm />;

      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
